import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import { GET_PORTFOLIO } from "../graphql";
import PhotoAlbum, { RenderPhoto } from "react-photo-album";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

interface ThumbnailType {
  url: string;
  width: number;
  height: number;
}

interface ImageType {
  url: string;
  width: number;
  height: number;
}

interface PortfolioType {
  name: string;
  thumbnail: ThumbnailType;
  images: ImageType[];
}

interface PortfolioData {
  portfolio: PortfolioType[];
}

const LoadingSpinner = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor="#333" stopOpacity="0" offset="0%" />
          <stop stopColor="#333" stopOpacity=".631" offset="63.146%" />
          <stop stopColor="#333" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            id="Oval-2"
            stroke="url(#a)"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill="#000" cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  );
};

const renderPhoto: RenderPhoto = ({
  layout,
  layoutOptions,
  imageProps: { onClick, title, alt, style, src },
}) => {
  return (
    <div
      onClick={onClick}
      className="bg-cover bg-center click"
      style={{
        width: style?.width,
        height: style?.height,
      }}
    >
      <div className="relative h-full cursor-pointer overflow-hidden bg-white hover:opacity-80">
        <div
          className="h-full bg-cover bg-center"
          style={{
            backgroundImage: `url('${src}')`,
          }}
        >
          <img alt={alt} style={{ opacity: "0%" }} src={src} />
          <div className="absolute top-3/4 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <h2 className="text-lg font-bold text-white hover:underline bg-black p-3 rounded-2 bg-opacity-40">
              {title}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Portfolio() {
  const [selectedGallery, selectGallery] = useState(null);
  const [selectedSlide, selectSlide] = useState(-1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { loading, error, data } = useQuery<PortfolioData>(GET_PORTFOLIO);

  const onClickAlbum = ({ photo }) => {
    selectGallery(photo.title);
    selectSlide(0);
  };

  if (loading)
    return (
      <div className="flex w-full justify-center">
        <LoadingSpinner />
      </div>
    );
  if (error) return <p>Error :(</p>;

  const galleries = data.portfolio.map((gallery) => ({
    src: gallery.thumbnail.url,
    width: gallery.thumbnail.width,
    height: gallery.thumbnail.height,
    title: gallery.name,
    alt: gallery.name,
  }));

  const images = _.flatMap(data.portfolio, (gallery) =>
    gallery.images.map((photo) => ({
      key: photo.url,
      src: photo.url,
      width: photo.width,
      height: photo.height,
      gallery: gallery.name,
      title: gallery.name,
    })),
  );
  const slides = _.filter(images, ["gallery", selectedGallery]).map(
    ({ src, width, height, title }) => ({
      src,
      title,
      aspectRatio: width / height,
    }),
  );

  return (
    <>
      <PhotoAlbum
        layout="columns"
        columns={isMobile ? 2 : 3}
        photos={galleries}
        spacing={0}
        renderPhoto={renderPhoto}
        onClick={({ photo }) => onClickAlbum({ photo })}
      />
      <Lightbox
        slides={slides}
        open={selectedSlide >= 0}
        index={selectedSlide}
        controller={{ closeOnBackdropClick: true }}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        animation={{
          swipe: 5,
          fade: 5,
        }}
        close={() => selectSlide(-1)}
      />
    </>
  );
}
