import React from "react";
import { EmailOutlined, GitHub, Instagram } from "@mui/icons-material";

const Navbar = () => {
  const navItems = [
    {
      label: "Instagram",
      href: "https://www.instagram.com/mehmetakyuz/",
      icon: <Instagram />,
    },
    {
      label: "Github",
      href: "https://github.com/mehmetakyuz",
      icon: <GitHub />,
    },
    {
      label: "Email",
      href: "mailto:mehmet@mehmetakyuz.net",
      icon: <EmailOutlined />,
    },
  ];
  return (
    <nav className="bg-black bg-opacity-40 top-0 absolute w-full flex flex-wrap items-center justify-between px-2 py-2">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="flex flex-grow items-center">
          <ul className="flex flex-row list-none ml-auto space-x-2">
            {navItems.map(({ label, href, icon }, index) => (
              <li key={index}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-white hover:bg-black hover:shadow-xl rounded-md px-2 py-1 flex items-center text-xs uppercase font-bold"
                  href={href}
                >
                  {icon}
                  <span className="inline-block ml-2">{label}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
