import React from "react";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Navbar from "./components/Navbar.tsx";
import { Analytics } from "@vercel/analytics/react";
import bg from "./assets/img/bg.jpg";
import avatar from "./assets/img/avatar.jpg";
import Portfolio from "./components/Portfolio.tsx";
import {
  CameraAlt,
  Email,
  FlightTakeoffOutlined,
  GitHub,
  GroupOutlined,
  Instagram,
  LinkedIn,
  PermIdentityOutlined,
  School,
  Work,
} from "@mui/icons-material";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  cache: new InMemoryCache(),
});

export default function Main() {
  const coverStyle = {
    backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.90), rgba(255, 255, 255, 0.0) 50%), url(${bg})`,
  };

  const curriculumVitae = {
    title: "Software Engineer & Landscape Photographer",
    intro:
      "Accidentally fell in love with photography. From Amsterdam,the Netherlands & I enjoy experimenting with lighting, techniques & travel photography.",
    name: "Mehmet Akyuz",
    bio: "I am a Staff Software Engineer at Secret Escapes with over 15 years of experience in the travel industry. I am leading the team that built and now owns and maintains the core dynamic packaging platform, integrating all major travel verticals, (Accommodation, Flights, Cars, Activities, Transfers) and has the mission to develop and maintain the technological capability to seamlessly source, dynamically package and offer trips combined with these products -- building backend based solutions for all user journey interactions pre-book and post-book.",
    highlights: [
      {
        icon: <GroupOutlined />,
        heading: "Engineering Management",
        content:
          "I have experience with technical leadership and management, coaching and mentoring senior engineers as well as collaborating across the tech and product organization working together with engineering managers, product owners, and senior engineers in managing cross-functional teams, overseeing large-scale software projects from conception to deployment and beyond.",
      },
      {
        icon: <FlightTakeoffOutlined />,
        heading: "Travel Technology Veteran",
        content:
          "Over 15 years of experience in the travel industry with the know-how of the underlying technology of the Dutch and European tourism landscape with experience rapidly developing, automating, and optimizing tour-operator (TO) and travel agency (OTA) systems, data modeling, developing product, pricing, availability and packaging systems, designing architecture and integrating travel web services.",
      },
    ],
    links: [
      {
        icon: <Email />,
        label: "mehmet@mehmetakyuz.net",
        href: "mailto:mehmet@mehmetakyuz.net",
      },
      {
        icon: <Instagram />,
        label: "instagram.com/mehmetakyuz",
        href: "https://instagram.com/mehmetakyuz",
      },
      {
        icon: <LinkedIn />,
        label: "linkedin.com/in/mehmetakyuz",
        href: "https://linkedin.com/in/mehmetakyuz",
      },
      {
        icon: <GitHub />,
        label: "github.com/mehmetakyuz",
        href: "https://github.com/mehmetakyuz",
      },
    ],
    categories: [
      {
        icon: <CameraAlt />,
        label: "Interests",
        nodes: [
          {
            label: "",
            content:
              "Photography, Astronomy, Physics, Fitness, Swimming, Travel, Science & Technology, Synesthesia, Psychology, the Copenhagen interpretation and Schrodinger's cat.",
          },
        ],
      },
      {
        icon: <School />,
        label: "Academia",
        nodes: [
          {
            label: "Msc. Information Studies",
            content: "University of Amsterdam",
          },
          {
            label: "Bsc. Computer Science / HBO-ICT",
            content: "Amsterdam University of Applied Sciences",
            nodes: [
              {
                label: "Specialization in Software Engineering",
              },
              {
                label: "9.0 GPA",
              },
            ],
          },
        ],
      },
    ],
    experience: [
      {
        title: "Staff Engineer (L5) / Tech Lead",
        company: "Secret Escapes",
        location: "Amsterdam / Remote",
        period: "2021 march - present",
        problemSpaces: [
          "Technical Leadership",
          "Engineering Management",
          "Scrumban",
          "Boy-scout principle",
          "Engineering Principles",
          "Graph Theory",
          "Combinatorial Optimization",
          "Travel Packaging Systems",
          "Inventory and Rate Systems",
          "Order Change Management",
          "Flights",
          "Accommodations",
          "Carrentals",
          "Activities",
        ],
        tech: [
          "Python",
          "Django",
          "Typescript",
          "Celery",
          "mySQL",
          "Redis",
          "RabbitMQ",
          "DynamoDB",
          "AWS Cloud",
          "Terraform",
          "Elasticsearch",
          "React",
          "GraphQL",
        ],
        nodes: [
          {
            content:
              "I am leading the team that built and now manages and owns the core dynamic packaging platform, integrating all major travel verticals, (Accommodation, Flights, Cars, Activities, Transfers) and has the mission to develop and maintain the technological capability to seamlessly source, dynamically package and offer trips combined with these products -- building backend based solutions for all user journey interactions pre-book and post-book.",
          },
          {
            content: "Experience and team responsibilities include;",
            nodes: [
              {
                content:
                  "Building and integrating high-availability API-based solutions within a service-oriented architecture",
              },
              {
                content:
                  "Developing internal tooling for availability and rates management, editorial content, and booking change management",
              },
              {
                content:
                  "Developing and maintaining a high-performance rule-based generalized pricing model based on high dimensional matrices using NumPy for efficient and large-scale price calculations.",
              },
              {
                content:
                  "Developing and extending the unique algorithmic dynamic packaging platform that powers all package deals and allows users to customize their package pre-book.",
              },
              {
                content:
                  "Integration with third-party providers of travel products through automation and mapping with the in-house developed dynamic packaging engine",
              },
              {
                content:
                  "Integration of payment service providers and implementation of payments, card authorization, multi-installment payment schedules, refunds, and virtual credit cards for supplier payments.",
              },
            ],
          },
          {
            content: "Daily/weekly responsibilities include;",
            nodes: [
              {
                content:
                  "Managing team responsibilities and weekly goals through kanban methodology. Having 1:1's, managing team performance and stakeholder expectations.",
              },
              {
                content:
                  "Driving and guiding technical decisions in and outside the team, and working closely with a product owner and stakeholders to continually iterate and improve on scalable solutions and services.",
              },
            ],
          },
        ],
      },
      {
        title: "Senior Software Engineer",
        company: "Secret Escapes",
        period: "2019 oct - 2021 march",
        problemSpaces: [
          "Domain modelling",
          "API Design & Integration",
          "Domain Driven Design",
          "Event Sourcing",
        ],
        tech: [
          "AWS",
          "Python",
          "Django",
          "Kinesis Firehose",
          "Redshift",
          "JavaScript",
          "Celery",
          "mySQL",
          "Redis",
          "RabbitMQ",
          "Elasticsearch",
          "React",
          "GraphQL",
        ],
        nodes: [
          {
            content:
              "Improving the product & packaging technology, and\n" +
              "                        integrating it within the Secret Escapes technology\n" +
              "                        stack.",
          },
          {
            content:
              " Implemented a robust streaming event pipeline using\n" +
              "                        Kinesis Firehose and Redshift for storing high volume\n" +
              "                        system metrics and clickstream data for analytical\n" +
              "                        reporting and BI/ML purposes.",
          },
        ],
      },
      {
        title: "Senior Software Engineer",
        company: "TravelBird",
        location: "Amsterdam",
        period: "2013 jan - 2019 oct",
        problemSpaces: [
          "Graph theory",
          "Concurrency",
          "Combinatorial optimization",
          "Domain modelling",
        ],
        tech: [
          "AWS",
          "Python",
          "Django",
          "JavaScript",
          "Celery",
          "mySQL",
          "Redis",
          "RabbitMQ",
          "Elasticsearch",
          "React",
          "GraphQL",
          "Apollo",
          "Redux",
          "Jenkins",
          "Travis",
          "Memcache",
        ],
        nodes: [
          {
            content:
              "Developed a travel dynamic packaging system that in\n" +
              "                        real-time finds the optimal solutions to packaging\n" +
              "                        multiple travel components. Reframed the system as a\n" +
              "                        combinatorial optimization search problem and developed\n" +
              "                        a heuristic algorithm that solves it pragmatically and\n" +
              "                        efficiently. It is capable of seamlessly offering trips\n" +
              "                        that can be enhanced with all major travel verticals\n" +
              "                        (Hotels, Flights, Cars, Activities, and Transfers) in\n" +
              "                        real-time, pre-book, and post-book.",
          },
          {
            content:
              "Designed and developed a product pricing & availability\n" +
              "                        platform in order to automate integration of\n" +
              "                        accommodations, activities and transportation data of\n" +
              "                        third parties into a unified system.",
          },
          {
            content:
              "Developed a hierarchical markup system to support\n" +
              "                        fine-grained pricing configuration for automated revenue\n" +
              "                        management.",
          },
          {
            content:
              "Developed a search & booking GraphQL API to support\n" +
              "                        real-time dynamic packaging of travel products based on\n" +
              "                        an incrementally refined user search query. Achieved a\n" +
              "                        +8% CR combined with an adapted UX.",
          },
        ],
      },
      {
        title: "IT Manager",
        company: "DTI Travel / Sky Airlines",
        location: "Amsterdam",
        period: "2006 dec - 2013 jan",
        problemSpaces: [
          "Web Development",
          "REST API's",
          "Task systems",
          "Domain modelling",
        ],
        tech: [
          "Microsoft Server",
          "Active Directory",
          "Linux",
          "Bare-metal servers",
          "Python",
          "Django",
          "MySQL",
          "Javascript",
          "jQuery",
          "Memcached",
          "Varnish",
          "Celery",
          "RabbitMQ",
        ],
        nodes: [
          {
            content: "Lead development of a B2B booking platform",
          },
          {
            content:
              "Implemented and maintained a web based B2B booking engine between Touroperators and Online travel-agencies (OTA's), used by 1800 travel agencies in the Netherlands\n" +
              "                        for live hotel & flight availability, price searches,\n" +
              "                        reservations and e-ticketing.",
          },
          {
            content:
              "Integrated hotel inventory of external hotel bedbanks and flight inventory from Amadeus using travel industry\n" +
              "                        protocols (OpenTravelService, G7, TravelTainment, Stadis\n" +
              "                        and TOMA) to a in-house built travel booking engine.",
          },
          {
            content:
              "Developed dashboards using data visualisation libraries for real-time metrics, KPI's and google analytics aggregated data.",
          },
          {
            content:
              "Developed a XML API abstraction for a legacy text-based TCP/IP-socket endpoint.",
          },
          {
            content: "Maintained the booking engine of the Sky Airline fleet",
          },
          {
            content:
              "Extended the search engine to support multi-day/leg/airport search capability",
          },
        ],
      },
    ],
  };

  return (
    <ApolloProvider client={client}>
      <Navbar />
      <main className="bg-black">
        <div className="flex content-center items-center justify-center">
          <div
            className="top-0 w-full h-full bg-center bg-cover"
            style={coverStyle}
          >
            <div className="container pt-32 pb-32 max-w-5xl mx-auto flex flex-wrap flex-col md:flex-row items-center">
              <div className="flex justify-center items-start p-3">
                <div className="w-48 h-48 rounded-full shadow-xl z-10">
                  <img
                    className="w-full h-full rounded-full object-cover object-right border-4 border-white z-10"
                    src={avatar}
                    alt="User Avatar"
                  />
                </div>
                <div className="w-2/3 text-white text-left ml-5 lg:mt-0">
                  <p className="text-5xl font-thin shadow">
                    {curriculumVitae.name}
                  </p>
                  <p className="text-lg md:text-3xl font-light shadow-sm drop-shadow-md">
                    {curriculumVitae.title}
                  </p>
                  <p className="mb-12 text-sm md:text-lg shadow">
                    {curriculumVitae.intro}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="relative p-8 pt-12 pb-20">
          <div className="h-full">
            <Portfolio />
          </div>
        </section>

        <section className="pb-20 relative bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              style={{ shapeRendering: "geometricPrecision" }}
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>

          <div className="container mx-auto px-4 pt-10 lg:pt-24 lg:pb-64 w-5/6">
            <div className="flex flex-wrap text-center justify-center mt-9">
              <div className="w-full lg:w-8/12 px-4">
                <h2 className="text-4xl font-thin text-white">
                  {curriculumVitae.name}
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-300 font-light">
                  {curriculumVitae.bio}
                </p>
              </div>
            </div>

            <div className="flex flex-wrap mt-12 justify-center">
              {curriculumVitae.highlights.map((highlight, index) => (
                <div className="w-full lg:w-1/2 px-4 text-center">
                  <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                    {highlight.icon}
                  </div>
                  <h6 className="text-xl mt-5 font-semibold text-white">
                    {highlight.heading}
                  </h6>
                  <p className="mt-2 mb-4 text-gray-200 font-extralight">
                    {highlight.content}
                  </p>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap mt-12 justify-center w-full text-gray-100">
              <div className="w-full md:w-1/3 space-y-4">
                <section>
                  <h2 className="text-xl mt-5 font-semibold text-white pb-4 space-x-2">
                    <PermIdentityOutlined />
                    <span>Personal</span>
                  </h2>
                  <div className="flex flex-col">
                    {curriculumVitae.links.map((link, index) => (
                      <div className="flex items-center text-white space-x-2">
                        {link.icon}
                        <a
                          className="icon-text hover:underline"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={link.href}
                        >
                          {link.label}
                        </a>
                      </div>
                    ))}
                  </div>
                </section>

                {curriculumVitae.categories.map((category, index) => (
                  <section className="space-y-2">
                    <h2 className="text-xl mt-5 font-semibold text-white pb-2 space-x-2">
                      {category.icon}
                      <span>{category.label}</span>
                    </h2>
                    {category.nodes.map((node, index) => (
                      <div className="flex" key={index}>
                        <div className="w-3/4">
                          <p className="font-bold">{node.label}</p>
                          <p className="mt-1">{node.content}</p>
                        </div>
                      </div>
                    ))}
                  </section>
                ))}
              </div>

              <div className="w-full md:w-2/3 md:right-column print:right-column">
                <section>
                  <h2 className="text-xl mt-5 font-semibold text-white pb-4 space-x-2">
                    <Work />
                    <span>Experience</span>
                  </h2>
                  {curriculumVitae.experience.map((experience, index) => (
                    <>
                      <div className="mt-2 mb-4 text-gray-300 prose">
                        <div>
                          <div className="text-white font-thin text-2xl">
                            {experience.title}
                          </div>
                          <div className="space-x-1">
                            <span>@</span>
                            <span>{experience.company}</span>
                          </div>
                          <div className="space-x-2">
                            <span className="text-xs">{experience.period}</span>
                            <span className="text-xs">
                              {experience.location}
                            </span>
                          </div>
                        </div>
                        <ul>
                          {experience?.nodes?.map((node, index) => (
                            <li>
                              {node.content}
                              {node.nodes && (
                                <ul>
                                  {node.nodes.map((subNode, subIndex) => (
                                    <li>{subNode.content}</li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-2 mb-4 text-gray-300 prose space-y-2">
                        {experience.problemSpaces && (
                          <div className="space-x-2">
                            <span className="text-white font-medium text-lg">
                              Problem Spaces
                            </span>
                            {experience?.problemSpaces.map((space, index) => (
                              <span className="inline-flex items-center rounded-md bg-red-800 px-2 py-1 text-xs font-medium text-gray-300 ring-1 ring-inset ring-gray-500/10">
                                {space}
                              </span>
                            ))}
                          </div>
                        )}
                        {experience.tech && (
                          <div className="space-x-2">
                            <span className="text-white font-medium text-lg">
                              Tech
                            </span>
                            {experience?.tech.map((tech, index) => (
                              <span className="inline-flex items-center rounded-md bg-gray-800 px-2 py-1 text-xs font-medium text-gray-300 ring-1 ring-inset ring-gray-500/10">
                                {tech}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                      <hr className="shadow border-gray-600 m-10" />
                    </>
                  ))}
                </section>
              </div>
            </div>
          </div>
        </section>
        <Analytics />
      </main>
    </ApolloProvider>
  );
}
