import { gql } from "@apollo/client";

export const GET_PORTFOLIO = gql`
  query getPortfolio {
    portfolio {
      name
      thumbnail {
        url
        width
        height
      }
      images {
        url
        width
        height
      }
    }
  }
`;
